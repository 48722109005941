import React from "react"
import Layout from "../components/Layout"
import { useStaticQuery, graphql } from "gatsby"
import styled from "@emotion/styled"
import { css } from "@emotion/core"
import mq from "../utils/breakpoints"
import { networks } from "../data/networks"

const TextLink = styled.p`
  font-size: 1.1rem;
  color: #000;
  text-decoration: none;
  transition: all 0.2s ease-in-out;
`

const FooterIcon = styled.a`
  display: flex;
  align-items: baseline;
  margin-top: 1.25rem;
  transition: all 0.12s ease-in-out;
  text-decoration: none;
  padding: 0.4rem 0.6rem;
  border-radius: 4px;

  &:hover {
    background-color: rgba(0, 0, 0, 0.06);

  }
`

function SocialList({ name, url, icon }) {
  return (
    <FooterIcon href={url} target="_blank">
      {icon}
      <TextLink css={{ margin: "0 0 0 5px" }}>{name}</TextLink>
    </FooterIcon>
  )
}

export default function Home() {
  const data = useStaticQuery(graphql`
    query {
      allHomepageJson {
        edges {
          node {
            title
            link
            img {
              childImageSharp {
                fluid(maxWidth: 980, quality: 90) {
                  ...GatsbyImageSharpFluid_noBase64
                }
              }
            }
          }
        }
      }
    }
  `)

  const homepageWorks = data.allHomepageJson.edges
  return (
    <Layout noFooter>
      <div>
        <section css={css`
            font-size: 2.75rem;
            margin-top: 0.75rem; 
            font-weight: 700; 
              ${mq[1]} {
                  font-size: 1.75rem;
                  line-height: 1.3;
              }`}>
          Hello! I'm&nbsp;
          <h1
            css={css`
              font-size: 2.75rem;
              display: inline;
              ${mq[1]} {
                  font-size: 1.75rem;
                  line-height: 1.3;
              }
            `}
          >
            Matthieu Caron
          </h1>
          , a developer interested in user interfaces, design system and websites.
        </section>

        <em css={{ marginTop: '1rem', display: "block" }}>
          A new version of this website is coming soon. Stay tuned!
        </em>
        <p
          css={{
            fontSize: "1.5rem",
            lineHeight: "2.2rem",
            maxWidth: 800,
            fontWeight: 400,
            color: "rgba(0, 0, 0, 0.6)",
          }}
        ></p>

        <div>
          <div
            css={{
              display: "flex",
              alignContent: "center",
              flexDirection: "row",
              marginBottom: "2rem",
              gap: '1rem'
            }}
          >
            {networks !== "undefined" &&
              networks.socialNetworks.map(network => {
                return (
                  <SocialList
                    key={network.idx}
                    name={network.name}
                    url={network.url}
                    icon={network.icon}
                  />
                )
              })}
          </div>
        </div>
        {/* <ProjectList isHomepage={true} works={homepageWorks} /> */}
      </div>
    </Layout>
  )
}
